import React from 'react';
import PropTypes from 'prop-types';
// import { v4 as uuidv4 } from 'uuid';
// import { SearchBox, useSearchBox } from 'react-instantsearch-hooks-web';

import './index.scss';

const SuggestingSearchBox = ({ suggestions, randomize }) => {
  console.log('ssb suggestions:', suggestions);
  console.log('ssb randomize:', randomize);

  // const { refine } = useSearchBox();

  return (
    <>
      {/* <SearchBox placeholder={suggestions[0]} autoFocus /> */}
      {/* <SearchBox placeholder={null} autoFocus /> */}
      <p style={{ display: 'none' }}>
        <strong>Search suggestions:</strong>
        {' '}
        {/* {suggestions.map((suggestion) => (
          <button
            key={uuidv4()}
            type="button"
            onClick={() => refine(suggestion)}
          >
            {suggestion}
          </button>
        ))} */}
      </p>
    </>
  );
};

SuggestingSearchBox.defaultProps = {
  suggestions: null,
  randomize: false,
};

SuggestingSearchBox.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  suggestions: PropTypes.arrayOf(PropTypes.string),
  randomize: PropTypes.bool,
};

export default SuggestingSearchBox;
